import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-selection',
  templateUrl: './login-selection.component.html',
  styleUrls: ['./login-selection.component.scss']
})
export class LoginSelectionComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  redirectToProjects(business){
    sessionStorage.setItem('business', JSON.stringify(business))
    this.router.navigate(['/projects']);
  }
}
