import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ISnackbarOptions, SnackbarConstants } from './snackbar.model';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  readonly DEFAULT_DISMISS = SnackbarConstants.DEFAULT_DISMISS;
  readonly DEFAULT_DURATION = SnackbarConstants.DEFAULT_DURATION;

  snackbarRef: MatSnackBarRef<SimpleSnackBar>;

  constructor(
    public snackbar: MatSnackBar,
    public translate: TranslateService,
  ) { }

  /**
   * Display a snackbar message
   *
   * @param {string} message Message to display; can be translated or a plain string
   * @param {MatSnackBarConfig} [options] Optional configuration
   */
  public open(message: string, options?: ISnackbarOptions) {
    const config: MatSnackBarConfig = {
      // Default options.
      duration: this.DEFAULT_DURATION,
      // Override with provided options.
      ...options
    };

    // Close already opened snackbar first.
    this.dismiss();

    // Dismiss action text.
    const dismissAction = options?.dismiss || this.DEFAULT_DISMISS;

    // Get translated text.
    const translated = this.translate.instant([message, dismissAction], options?.translate);

    // Display the snackbar, storing the reference.
    this.snackbarRef = this.snackbar.open(translated[message], translated[dismissAction], config);
  }

  /**
   * Get the snackbar reference
   * - Can be useful for defining a callback after the snackbar is dismissed, via snackbarRef.afterOpened().subscribe(...)
   */
  public getSnackbarRef() {
    return this.snackbarRef;
  }

  /**
   * Dismiss the snackbar
   */
  public dismiss() {
    this.snackbar.dismiss();
  }
}
